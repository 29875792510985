.cuadroGeneral {
    width: 100%;
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    margin-top: "30px";
}

.categoria {
    padding-top: 10px;

    font-size: 30px;
}

.gup {
    font-size: 25px;
    padding-bottom: 10px;
}

.fecha {
    display: flex;
    font-size: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    height: 33%;
}

.certificacion {
    display: flex;
    flex: 1;
    font-size: 20px;

    align-items: center;
    justify-content: center;
}

.caja2 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 800px) {
    .cuadroGeneral {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .categoria {
        font-size: 25px;
    }

    .gup {
        font-size: 20px;

        padding-bottom: 10px;
    }

    .certificacion {
        font-size: 18px;
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .caja2 {
        height: 100px;
    }
}
